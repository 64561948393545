import { IonPage, IonTitle, IonLabel, IonContent, IonIcon, IonButton } from '@ionic/vue';
import { arrowForwardOutline } from 'ionicons/icons';
import { clearData, getInfo } from '@/services/users';
export default {
  name: 'NotFound',
  components: {
    IonPage,
    IonLabel,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton
  },

  setup() {
    return {
      arrowForwardOutline
    };
  },

  data() {
    return {
      user: null
    };
  },

  async mounted() {
    this.user = await getInfo();
  },

  methods: {
    goToConnexionPage() {
      clearData();
      this.$router.push('/connexion');
    },

    goToHomepage() {
      if (this.user) {
        if (this.user.userData.agent.kind === 'driver') {
          this.$router.push('/planning');
        } else if (this.user.userData.company.kind === 'renter') {
          this.$router.push('/el/planning');
        } else if (this.user.userData.company.kind === 'builder') {
          console.log('Partie de la plateforme pour cet utilisateur pas encore terminée');
        }
      }
    }

  }
};