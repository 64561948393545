<template>
  <ion-page>
    <WhiteHeader
      v-if="typeVue === 'create'"
      :back-button="true"
      :route-back="
        '/chantier/' + this.$route.params.id + '/' + this.$route.params.idRide
      "
      title="Création d'un BL"
    />

    <WhiteHeader
      v-else
      :back-button="true"
      :route-back="
        '/chantier/' + this.$route.params.id + '/' + this.$route.params.idRide
      "
      :title="oldRentalCoupon.name"
    />

    <ion-content class="ion-padding">
      <form @submit.prevent="sendRentalCoupon()">
        <ion-grid>
          <!-- v-if to avoid race condition -->
          <date
            v-if="start && end"
            v-model:start="start"
            v-model:end="end"
            v-model:start-default="startDefault"
            v-model:end-default="endDefault"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-on:updateDateDependent="updateInformationsDateDependent"
          />
        </ion-grid>

        <prestation
          v-model:segment-activities="segmentActivities"
          v-model:segment-prestation="segmentPrestation"
          v-model:segment-bad-weather="segmentBadWeather"
          v-model:segment-maintenance="segmentMaintenance"
          v-model:times="times"
          v-model:old-rental-coupon-sending="oldRentalCouponSending"
          v-model:readable-date="readableDate"
          v-model:start="start"
          v-model:end="end"
          v-model:initialized="initialized"
          v-model:pricing-information="mission.pricing_information"
          v-model:amount-flat-rates="mission.amount_flat_rates"
          v-model:selected-modifiers="selectedModifiers"
          :modifiers="mission.modifiers"
          :edit="this.typeVue !== 'create'"
        />

        <ion-grid>
          <client-details
            v-model:builder_name="source_builder_name"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-model:worksite_name="source_worksite_name"
            v-model:old-contact-id="oldRentalCoupon.source_contact_id"
            v-model:old-contact-email="oldRentalCoupon.source_contact_email"
            v-model:type-vue="typeVue"
            :toggle-show="true"
            :type-mission="typeMission"
          />

          <internal-references
            v-model:worksite_reference="worksite_reference"
            v-model:order_number="order_number"
          ></internal-references>
        </ion-grid>

        <ion-grid>
          <options
            ref="options"
            v-model:toggle-machine-counter="toggleMachineCounter"
            v-model:toggle-comment="toggleComment"
            v-model:pricing-fuel="pricing_fuel"
            v-model:machine_counter_start="machine_counter_start"
            v-model:machine_counter_end="machine_counter_end"
            v-model:comments="comments"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-model:type-mission="typeMission"
            v-model:segment-contact="segmentSourceContact"
            v-model:contact_id="source_contact_id"
            v-model:supervisors-builder="sourceSupervisorsBuilder"
            v-model:contact_name="source_contact_name"
            v-model:builder_id="mission.builder_id"
            v-model:contact_email="source_contact_email"
            v-model:contact_phone="source_contact_phone"
            v-model:status="status"
            v-model:attachments="oldRentalCoupon.attachments"
            :type-vue="typeVue"
            :files-too-big="filesTooBig"
            :can-add-attachments="canAddAttachments"
          />

          <signature
            v-model:did_signature_driver="did_signature_driver"
            v-model:canvas_signature_driver="canvas_signature_driver"
            v-model:old_draw_signature_driver="old_draw_signature_driver"
            v-model:did_signature_contact="did_signature_contact"
            v-model:canvas_signature_contact="canvas_signature_contact"
            v-model:old_draw_signature_contact="old_draw_signature_contact"
            v-model:no_signature_contact="no_signature_contact"
            v-model:signature_contact="oldRentalCoupon.signature_contact"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            :is-transport="false"
            v-on:clearSignature="clearSignature"
            v-on:startSignature="startSignature"
          />

          <internal-information
            ref="internalInformation"
            v-model:notes="notes"
            :type-vue="typeVue"
            :status="status"
            :files-too-big="filesTooBig"
            v-model:attachments="oldRentalCoupon.attachments"
            :can-add-attachments="canAddAttachments"
          />

          <ion-row>
            <ion-col>
              <ion-button
                v-if="
                  oldRentalCoupon &&
                  oldRentalCoupon.status === 'send' &&
                  oldRentalCoupon.source_contact_email !== source_contact_email
                "
                color="success"
                class="mt-10"
                strong="true"
                expand="block"
                type="submit"
                >Envoyer</ion-button
              >

              <ion-button
                v-else-if="oldRentalCoupon && oldRentalCoupon.status === 'send'"
                color="tertiary"
                class="mt-10"
                strong="true"
                expand="block"
                type="submit"
                >Enregistrer</ion-button
              >

              <ion-button
                v-else
                :color="status === 'save' ? 'tertiary' : 'success'"
                class="mt-10"
                strong="true"
                expand="block"
                type="submit"
                >{{ status === 'save' ? 'Enregistrer' : 'Envoyer' }}</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from '@ionic/vue';
import {
  getDetailMission,
  getMissionOrder,
} from '@/services/driver/missionsOrders';
import {
  getAllSupervisorOfBuilder,
  fetchRentalCoupon,
} from '@/services/driver/rentalCoupon';
import { getInfo } from '@/services/users';
import Date from '@/components/driver/rentalCoupon/components/Date';
import ClientDetails from '@/components/driver/rentalCoupon/components/ClientDetails';
import Prestation from '@/components/driver/rentalCoupon/components/Prestation';
import Options from '@/components/driver/rentalCoupon/components/Options';
import Signature from '@/components/driver/rentalCoupon/components/Signature';
import InternalInformation from '@/components/driver/rentalCoupon/components/InternalInformation';
import InternalReferences from '@/components/driver/rentalCoupon/components/InternalReferences';
import { mixinDataAllTypeRentalCoupon } from '@/components/driver/rentalCoupon/mixins/DataAllTypeRentalCoupon';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import utils from '@/services/utils/Utils';

const dayjs = require('dayjs');
require('dayjs/locale/fr');
dayjs.locale('fr');

export default {
  name: 'RentalWorksite',
  components: {
    WhiteHeader,
    InternalInformation,
    InternalReferences,
    Signature,
    Options,
    Prestation,
    ClientDetails,
    Date,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  mixins: [mixinDataAllTypeRentalCoupon],
  data() {
    return {
      // Useful for the operation of the form
      toggleMachineCounter: false,

      // Variable storing the form data
      machine_counter_start: '',
      machine_counter_end: '',
    };
  },
  async mounted() {
    // Get information on the mission
    // we pass the rental coupon id only if we don't have reservation
    // don't know why but here null is considered like a string
    this.mission = await getMissionOrder(
      this.$route.params.id,
      this.$route.params.idRide,
      this.typeMission,
      this.$route.params.idRentalCoupon,
    );

    if (!this.mission.pricing_information) {
      utils.routeForbidden(this.$router, this.$route);
    }

    this.missionDetail = await getDetailMission(
      this.typeMission,
      this.$route.params.id,
      null,
      this.$route.params.idRentalCoupon,
    );

    const { userData } = await getInfo();
    this.user = userData;

    this.initializationSignatureData();

    this.startDefault = this.mission.start;
    this.endDefault = this.mission.end;
    this.source_builder_name = this.mission.builder;

    if (this.typeVue !== 'create') {
      this.oldRentalCoupon = await fetchRentalCoupon(
        this.$route.params.idRentalCoupon,
      );

      if (this.oldRentalCoupon.status === 'send') {
        this.oldRentalCouponSending = true;
      }

      this.start = this.oldRentalCoupon.start;
      this.end = this.oldRentalCoupon.end;
      if (this.oldRentalCoupon.start < this.startDefault) {
        this.startDefault = this.oldRentalCoupon.start;
      }
      if (this.oldRentalCoupon.end > this.endDefault) {
        this.endDefault = this.oldRentalCoupon.end;
      }

      this.pricing_fuel = this.oldRentalCoupon.pricing_fuel;
      this.pricing_driver = this.oldRentalCoupon.pricing_driver;
      this.comments = this.oldRentalCoupon.comments;
      if (this.oldRentalCoupon.machine_counter_start) {
        this.machine_counter_start =
          this.oldRentalCoupon.machine_counter_start.toString();
      }
      if (this.oldRentalCoupon.machine_counter_end) {
        this.machine_counter_end =
          this.oldRentalCoupon.machine_counter_end.toString();
      }
      this.old_draw_signature_contact = this.oldRentalCoupon.signature_contact;
      this.old_draw_signature_driver = this.oldRentalCoupon.signature_driver;
      this.no_signature_contact = this.oldRentalCoupon.no_signature_contact;

      this.setModifiersKeys(this.oldRentalCoupon.modifiers);

      this.setToggle(this.typeMission);
    } else {
      // When creating
      this.start = this.mission.start;
      this.end = this.mission.end;

      if (this.mission.pricing_information) {
        this.times.prestation.type = this.getPrestationType(this.mission);
      }

      this.setRoadInfo();
    }

    this.source_worksite_name = this.setWorksiteTextForm(
      this.oldRentalCoupon.source_worksite_name,
      this.mission.worksite_name,
      this.mission.worksite_address,
      this.mission.worksite_city,
      this.mission.worksite_manual,
    );
    await this.setSupervisorsInformationForm();
    this.setInformationsDateDependent();
    this.setInternalReferences();

    if (this.typeVue === 'create') {
      this.setSelectedModifiers(this.mission.modifiers);
    }
  },
  methods: {
    /**
     * Get all supervisors of the company if is necessary
     */
    async setSupervisorsInformationForm() {
      if (this.mission.builder_id) {
        this.sourceSupervisorsBuilder = await getAllSupervisorOfBuilder(
          this.mission.builder_id,
        );

        if (this.sourceSupervisorsBuilder.length === 0) {
          this.sourceSupervisorsBuilder.push({
            name: this.missionDetail.manager,
            id: this.missionDetail.manager_id,
            email: this.missionDetail.manager_email,
          });
        }

        if (this.typeVue !== 'create') {
          this.source_contact_email = this.oldRentalCoupon.source_contact_email;

          if (this.oldRentalCoupon.source_contact_id) {
            this.segmentSourceContact = 'available';
            this.source_contact_id = this.oldRentalCoupon.source_contact_id;
            return;
          }
          this.source_contact_name = this.oldRentalCoupon.source_contact_name;
          this.source_contact_id = this.sourceSupervisorsBuilder[0]['id'];
          return;
        }

        if (this.missionDetail.manager_id) {
          this.segmentSourceContact = 'available';
          this.source_contact_id = this.missionDetail.manager_id;
          this.source_contact_email = this.missionDetail.manager_email;
          this.source_contact_phone = this.missionDetail.manager_phone;
          return;
        }
        this.source_contact_name = this.missionDetail.manager;
        this.source_contact_id = this.sourceSupervisorsBuilder[0]['id'];
        return;
      }

      this.source_contact_name = this.missionDetail.manager;
    },

    /**
     * Filling in the object containing the information on the rental coupon and sending it to the server
     */
    async sendRentalCoupon() {
      if (
        !this.source_contact_email &&
        !this.source_contact_phone &&
        this.status === 'send' &&
        (await this.sendWithNoEmail())
      ) {
        return;
      }

      if (this.typeVue !== 'create' && this.oldRentalCoupon.status !== 'save') {
        // When updating a sent coupon, only allow to change contact emails & internal notes
        this.rentalCoupon = this.oldRentalCoupon;

        this.rentalCoupon.source_contact_email = this.source_contact_email;
        this.rentalCoupon.notes = this.notes;
      } else {
        // Else, allow to update everything
        this.rentalCoupon = {
          ride_id: this.mission.id,
          reservation_id: this.mission.id_reservation,
          driver_id: Number,
          machine_id: this.mission.machine_id,

          status: this.status,
          start: this.start,
          end: this.end,

          source_builder_id: null,
          source_builder_name: null,
          source_contact_id: null,
          source_contact_name: null,
          source_contact_email: this.source_contact_email,
          source_worksite_id: null,
          source_worksite_name: null,

          prestation_times: this.completeTimes(this.times.prestation),
          bad_weather_times: this.completeTimes(this.times.badWeather),
          maintenance_times: this.completeTimes(this.times.maintenance),
          pricing_fuel: this.pricing_fuel,
          pricing_driver: this.pricing_driver,
          comments: this.comments,
          machine_counter_start: null,
          machine_counter_end: null,
          signature_contact: null,
          signature_driver: null,
          no_signature_contact: this.no_signature_contact,

          notes: this.notes,
          worksite_reference: this.worksite_reference,
          order_number: this.order_number,

          pricing_information: this.mission.pricing_information,
          amount_flat_rates: this.mission.amount_flat_rates,
          added_modifiers: Object.values(this.selectedModifiers),

          billing_company_id: this.mission.billing_company_id,
          custom_articles: this.mission.custom_articles,
          custom_modifiers: this.mission.custom_modifiers,

          applicant_email: this.mission.applicant_email,
          related_to: this.mission.related_to,
        };

        const { userData } = await getInfo();
        this.rentalCoupon.driver_id = userData.agent_id;

        this.completeSourceBuilder();
        this.completeSourceContact();
        this.completeSourceWorksite();

        this.completeMachineCounteur();
        this.saveSignature('contact');
        this.saveSignature('driver');
      }

      if (this.typeVue !== 'create') {
        this.rentalCoupon['id'] = this.oldRentalCoupon.id;
      }

      const optionsVue = this.$refs.options;
      const internalInformationVue = this.$refs.internalInformation;

      this.rentalCoupon.attachmentsToDelete = [
        ...optionsVue.getAttachmentsToDelete(),
        ...internalInformationVue.getAttachmentsToDelete(),
      ].join('|');

      this.rentalCoupon.attachmentsToUpdate = {
        ...optionsVue.getAttachmentsToUpdate(),
        ...internalInformationVue.getAttachmentsToUpdate(),
      };

      const res = await this.send([
        ...optionsVue.getWaitingFiles(),
        ...internalInformationVue.getWaitingFiles(),
      ]);

      // if go back to "listeBonLocation" month will still be correct because the component is not unmounted so data is not reset
      let path = '/listeBonLocation';
      const query = {};

      // if not on "listeBonLocation", go back to associated worksite page
      if (!this.$router.options.history.state.back.includes(path)) {
        path = `/chantier/${this.rentalCoupon.reservation_id}/${this.$route.params.idRide}`;
      } else {
        query.forceRefresh = true;
      }

      if (res && res.status && res.status === 'ok') {
        optionsVue.resetFilesInformation();
        internalInformationVue.resetFilesInformation();

        await this.$router.replace({ path, query });
      }
    },

    completeSourceBuilder() {
      if (this.mission.builder_id) {
        this.rentalCoupon.source_builder_id = this.mission.builder_id;
        return;
      }
      this.rentalCoupon.source_builder_name = this.source_builder_name;
    },

    completeSourceContact() {
      if (this.segmentSourceContact === 'available') {
        this.rentalCoupon.source_contact_id = this.source_contact_id;
        return;
      }
      this.rentalCoupon.source_contact_name = this.source_contact_name;
    },

    /**
     * Before sending coupon we need to check if the worksite changed
     * allow to set the worksite_id if possible
     *
     * Please keep in the same format as :
     * completeSourceWorksite, completeTargetWorksite inside dataTransportRentalCoupon
     */
    completeSourceWorksite() {
      const defaultWorksiteName = this.setWorksiteTextForm(
        this.oldRentalCoupon.source_worksite_name,
        this.mission.worksite_name,
        this.mission.worksite_address,
        this.mission.worksite_city,
        null,
      );

      if (
        defaultWorksiteName === this.source_worksite_name &&
        this.mission.worksite_id
      ) {
        this.rentalCoupon.source_worksite_id = this.mission.worksite_id;
        return;
      }

      this.rentalCoupon.source_worksite_name = this.source_worksite_name;
    },

    setModifiersKeys(modifiers) {
      modifiers.forEach(modifier => {
        this.selectedModifiers[modifier.name] = modifier;
      });
    },
  },
};
</script>
