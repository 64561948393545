import { IonContent, IonInput, IonButton, IonLabel } from '@ionic/vue';
export default {
  name: 'PopoverAddMachine',
  components: {
    IonContent,
    IonInput,
    IonButton,
    IonLabel
  },
  props: ['closePopover'],

  data() {
    return {
      textNewMachine: ''
    };
  }

};